body {
  color: #0F3F62;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-head-bold > thead > tr > th{
  font-weight: bold;
}

.btn-action > div > button{
  font-size: 1.1rem!important;
}

.tr-hover:hover{
  background-color: rgba(172,13,13,0.1)!important;
}

.tr-hover{
  cursor: pointer!important;
}

div[data-popper="popper1"]{
  width: 25vw!important;
  line-height: 2;
}

.popover-body{
  padding: 0
}

.popover-body > a{
  font-size: 1.2rem;
  padding: 4px 12px;
}

.popover-body > a > i{
  color: #E32F35;
}

.popover-body > a:hover{
  background-color: rgba(172,13,13,0.1)!important;
}

.badge-success {
  color: #0e6245 !important;
  background-color: #cbf4c9 !important;
}

.badge-warning {
  color: #983705 !important;
  background-color: #F8E5D0 !important;
}

.MuiButton-textPrimary{
  background-color: #5469d4!important;
  color: white!important;
}

.MuiButton-textPrimary.Mui-disabled{
  background-color: rgba(84, 105, 212, 0.26) !important;
  color: white!important;
}

.MuiButton-textSecondary{
  color: #E02020!important;
}

.MuiButton-text.badge-success.Mui-disabled{
  color: #0e6245 !important;
  background-color: rgba(203, 244, 201, 0.26) !important;
}